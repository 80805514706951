import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { Autocomplete } from './AutocompleteNew';
import Link from 'next/link'
import Image from 'next/image';
import { useRouter } from 'next/router';

const appId = 'UKQLSUI7R1';
const apiKey = 'aaf591635871a36919ac0f78f7c1fd8a';
const searchClient = algoliasearch(appId, apiKey);

function AlgoliaSearch() {
    const router = useRouter()
    const handleClickScrollToNews = () => {
        const element = document.getElementById('section-1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickScrollToPress = () => {
        const element = document.getElementById('section-2');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickScrollToCase = () => {
        const element = document.getElementById('section-3');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickScrollToReports = () => {
        const element = document.getElementById('section-4');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickScrollToInfo = () => {
        const element = document.getElementById('section-5');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickScrollToBlogs = () => {
        const element = document.getElementById('section-6');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="app-container mb-6">
            <Autocomplete
                openOnFocus={true}
                placeholder="What are you looking for?"
                autoFocus={true}
                detachedMediaQuery=''
                // defaultActiveItemId={0}
                // detachedSearchButtonQuery="test"
                insights={true}
                // onSubmit={({ state }) => {
                //     const noResults = state.collections.every(collection => collection.items?.length === 0);
                //     if (noResults) {
                //         router.push(`/search?q=${state.query}`)
                //     } 
                // }}

                onSubmit = {({state})=>{
                      router.push(`/search?q=${state.query}`)
                }}
                // plugins= {[querySuggestionsPlugin , recentSearchesPlugin]}
                getSources={({ query }) => [
                    {
                        sourceId: 'reports',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'reports',
                                        query,
                                        params: {
                                            hitsPerPage: 7,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return process.env.APP_URL + '/report-store/' + item?.slug;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.name} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        {/* <div className='pl-3'>
                                            <span className="aa-SourceHeaderTitle pl-3 ">
                                                Can&apos;t find what you&apos;re looking for?
                                            </span>
                                            <div className="aa-SourceHeaderLine" />
                                        </div> */}
                                        <div className='overflow-x-scroll lg:overflow-x-hidden xl:overflow-x-hidden md:max-w-full md:w-[69%] xl:max-w-none fixed xl:w-[69%] w-[93%] pt-[0.60rem] lg:top-[83px] xl:top-[83px] top-[66px] pl-1 md:top-[83px] bg-white' style={{ zIndex: '99999999'}}>
                                            <div className='flex justify-start gap-2 pb-2'>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToReports}>
                                                    Reports
                                                </div>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToBlogs}>
                                                    Blogs
                                                </div>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToInfo}>
                                                    Infographics
                                                </div>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToNews}>
                                                    NewslettersWhitepapers
                                                </div>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToPress}>PressReleases
                                                </div>
                                                <div
                                                    className='hover:bg-gray-100 text-gray-800 border px-3 py-1 text-sm cursor-pointer'
                                                    onClick={handleClickScrollToCase}>CaseStudies
                                                </div>
                                            </div>
                                        </div>
                                        <p id="section-4" className='mt-[60px] pl-3 text-[14px] font-semibold bg-gray-100 py-2 text-[#3E34D3] mt-1'>Healthcare and Medtech Research Reports</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={process.env.APP_URL + "/report-store/" + item?.slug}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.featured_image ? process.env.IMG_CDN + item?.featured_image : process.env.IMG_CDN + "img/report-store/jpg/488592_Acromegaly_-_Pipeline_Insights,2018.jpg"} alt={item?.featured_image_alt} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="name" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                                                                <path d="M19 5h-14l1.5-2h11zM21.794 5.392l-2.994-3.992c-0.196-0.261-0.494-0.399-0.8-0.4h-12c-0.326 0-0.616 0.156-0.8 0.4l-2.994 3.992c-0.043 0.056-0.081 0.117-0.111 0.182-0.065 0.137-0.096 0.283-0.095 0.426v14c0 0.828 0.337 1.58 0.879 2.121s1.293 0.879 2.121 0.879h14c0.828 0 1.58-0.337 2.121-0.879s0.879-1.293 0.879-2.121v-14c0-0.219-0.071-0.422-0.189-0.585-0.004-0.005-0.007-0.010-0.011-0.015zM4 7h16v13c0 0.276-0.111 0.525-0.293 0.707s-0.431 0.293-0.707 0.293h-14c-0.276 0-0.525-0.111-0.707-0.293s-0.293-0.431-0.293-0.707zM15 10c0 0.829-0.335 1.577-0.879 2.121s-1.292 0.879-2.121 0.879-1.577-0.335-2.121-0.879-0.879-1.292-0.879-2.121c0-0.552-0.448-1-1-1s-1 0.448-1 1c0 1.38 0.561 2.632 1.464 3.536s2.156 1.464 3.536 1.464 2.632-0.561 3.536-1.464 1.464-2.156 1.464-3.536c0-0.552-0.448-1-1-1s-1 0.448-1 1z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    },
                    {
                        sourceId: 'delve_blogsearchable_posts',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'delve_blogsearchable_posts',
                                        query,
                                        params: {
                                            hitsPerPage: 5,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return item?.permalink;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.post_title} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        <p id="section-6" className='pl-3 font-semibold bg-gray-100 text-[#3E34D3] py-2 text-[14px]'>Blogs</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={item?.permalink}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.images?.thumbnail?.url} alt={item?.post_title} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="post_title" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className='text-gray-100'>
                                                                <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    },
                    {
                        sourceId: 'infographics',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'infographics',
                                        query,
                                        params: {
                                            hitsPerPage: 5,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return process.env.APP_URL + '/infographics/' + item?.slug;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.name} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        <p id="section-5" className='pl-3 font-semibold bg-gray-100 text-[#3E34D3] py-2 text-[14px]'>Report Infographics</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={process.env.APP_URL + "/infographics/" + item?.slug}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.thumb ? process.env.IMG_CDN + item?.thumb : process.env.IMG_CDN + "img/report-store/jpg/488592_Acromegaly_-_Pipeline_Insights,2018.jpg"} alt={item?.thumb_alt} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="name" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className='text-gray-100'>
                                                                <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    },
                    {
                        sourceId: 'newsletterswhitepapers',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'newsletterswhitepapers',
                                        query,
                                        params: {
                                            hitsPerPage: 5,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return process.env.APP_URL + '/whitepaper-newsletter/' + item?.slug;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.title} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        <p id="section-1" className='pl-3 font-semibold bg-gray-100 py-2 text-[#3E34D3] text-[14px]'>Newsletter and whitepaper</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={process.env.APP_URL + "/whitepaper-newsletter/" + item?.slug}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.image ? process.env.IMG_CDN + item?.image : process.env.IMG_CDN + "img/report-store/jpg/488592_Acromegaly_-_Pipeline_Insights,2018.jpg"} alt={item?.image_alt} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="title" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className='text-gray-100'>
                                                                <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z"></path>
                                                            </svg>

                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    },
                    {
                        sourceId: 'press_release_index',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'press_release_index',
                                        query,
                                        params: {
                                            hitsPerPage: 5,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return item?.link;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.title} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        <p id="section-2" className='pl-3 font-semibold bg-gray-100 text-[#3E34D3] py-2 text-[14px]'>Healthcare Press Releases</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={item?.link}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.featured_image ? process.env.IMG_CDN + item?.featured_image : process.env.IMG_CDN + "img/report-store/jpg/488592_Acromegaly_-_Pipeline_Insights,2018.jpg"} alt={item?.image_alt} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="title" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className='text-gray-100'>
                                                                <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    },
                    {
                        sourceId: 'case_studies',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'case_studies',
                                        query,
                                        params: {
                                            hitsPerPage: 5,
                                        },
                                    },
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return process.env.APP_URL + '/case-study/' + item?.slug;
                        },
                        onActive({ item, setContext }) {
                            setContext({ preview: item });
                        },
                        onSelect({ item, setQuery, setIsOpen, refresh }) {
                            setQuery(`${item?.title} `);
                            setIsOpen(true);
                            refresh();
                        },
                        templates: {
                            header({ Fragment, state, children, components }) {
                                return (
                                    <>
                                        <p id="section-3" className='pl-3 font-semibold bg-gray-100 text-[#3E34D3] py-2 text-[14px]'>Case Studies</p>
                                    </>
                                )
                            },
                            item({ item, components }) {
                                return (
                                    <>
                                        <div className="aa-ItemWrapper">
                                            <Link href={process.env.APP_URL + "/case-study/" + item?.slug}>
                                                <div className="aa-ItemContent py-2">
                                                    <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                                        <Image src={item?.image ? process.env.IMG_CDN + item?.image : process.env.IMG_CDN + "img/report-store/jpg/488592_Acromegaly_-_Pipeline_Insights,2018.jpg"} alt={item?.image_alt} width={1000} height={1000} />
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle text-[14px]">
                                                            <components.Highlight hit={item} attribute="title" />
                                                        </div>
                                                    </div>
                                                    <div className="aa-ItemActions">
                                                        <button
                                                            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                            type="button"
                                                            title="Select"

                                                        >
                                                            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                                <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="aa-ItemActionButton"
                                                            type="button"
                                                            title="Documents"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className='text-gray-100'>
                                                                <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            },
                            noResults({ state }) {
                                return (
                                    <>No matches found <strong>&quot;{state.query}&quot;</strong></>
                                );
                            },
                        }
                    }
                ]}
            />
        </div >
    );
}

export default AlgoliaSearch;
